import { Component, OnInit } from '@angular/core';
import { FoundationService } from '@app/_shared/foundation.service';
import { first } from 'rxjs/operators';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loading: boolean = false;
  isCamera: boolean = false;
  reader: FileReader = new FileReader;
  url: string = './../assets/images/demo.png';
  imgFile = undefined;
  isImage: boolean;
  outputData;
  outputImages;
  productData = [
    { name: "NS1", image: "./../assets/products/N1.webp" },
    { name: "NS2", image: "./../assets/products/N2.webp" },
    { name: "NS3", image: "./../assets/products/N3.webp" },
    { name: "NS4", image: "./../assets/products/N4.webp" },
    { name: "NS5", image: "./../assets/products/N5.webp" },
    { name: "NS6", image: "./../assets/products/N6.webp" },
    { name: "NS7", image: "./../assets/products/N7.webp" },
  ];
  product;
  isError: boolean = false;
  error;
  public skintones = ['', 'Classic Beige', 'Fair Porcelain', 'Fair Ivory', 'Porcelain', 'Natural Ivory', 'Ivory', 'Light Beige', 'Classic Ivory', 'Creamy Beige', 'Soft Sand', 'Nude Beige', 'Warm Nude', 'Buff Beige', 'Natural Beige', 'True Beige', 'Soft Tan', 'Natural Buff', 'Pure Beige', 'Rich Tan', 'Light Honey', 'Sun Beige', 'Golden', 'Natural Tan', 'Warm Honey', 'Toffee', 'Golden Caramel', 'Warm Sun', 'Classic Tan', 'Spicy Brown', 'Cappuccino', 'Coconut', 'Warm Coconut', 'Latte', 'Mocha', 'Truffle', 'Nutmeg', 'Deep Golden', 'Deep Bronze', 'Java', 'Espresso'];
  // detectedSkintone;
  ogImage;

  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 375},
    height: { ideal: 460 }
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = <any>'./../assets/skin_analysis.gif';

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  constructor(private _foundationService: FoundationService, private _ngxService: NgxUiLoaderService) { }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    this._ngxService.start();
  }

  startWebcam() {
    this.isImage = false;
    this.showWebcam = true;
  }

  public triggerSnapshot(): void {
    this.showWebcam = false;
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.url = webcamImage.imageAsDataUrl;
    this.ogImage = this.url;

    // Base64 url of image trimmed one without data:image/png;base64
    const base64 = webcamImage.imageAsBase64;
    // Naming the image
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    // Replace extension according to your media type
    const imageName = date + '.' + text + '.jpeg';
    // call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(base64);

    this.imgFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    this.isImage = true;
    this.checkFoundation();
  }

  public cameraWasSwitched(deviceId: string): void {
    // console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  changeImage(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.imgFile = event.target.files[0];

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = (<FileReader>event.target).result as string;
        this.ogImage = this.url;
      };
      this.isImage = true;
      this.checkFoundation();
    }
  }

  onClick(e) {
    switch (e.target.id) {
      case 'btn0': {
        this.url = this.ogImage;
        break;
      }
      case 'btn1': {
        this.url = this.outputImages.found;
        break;
      }
      case 'btn2': {
        this.url = this.outputImages.found2;
        break;
      }
      case 'btn3': {
        this.url = this.outputImages.found3;
        break;
      }
      case 'btn4': {
        this.url = this.outputImages.found4;
        break;
      }
      case 'btn5': {
        this.url = this.outputImages.found5;
        break;
      }
      default: return;
    }
  }

  checkFoundation() {
    this._ngxService.start();
    this.loading = true;
    this.isError = false;
    this._foundationService.checkFoundation(this.imgFile)
      .pipe(first()).subscribe(data => {
        // console.log(data);
        this._ngxService.stop();
        this.loading = false;
        this.outputData = data['outputData'];
        this.outputImages = data['outputImages'];
        //Object.assign(this.outputImages,{found1Name:'MediumBrown Neutral 6'},{found2Name:'MediumBrown Neutral 6'},{found3Name:'MediumBrown Neutral 6'},{found4Name:'MediumBrown Neutral 6'})
        console.log(this.outputImages)

        // this.detectedSkintone = this.skintones[this.outputData.skin_tone_type]
        // this.product = this.productData.find(item => item.name === this.outputData.recommendedTone1.toUpperCase());
      },
        (err: HttpErrorResponse) => {
          this._ngxService.stop();
          this.isError = true;
          this.error = err.error.error;

          console.log('Error occurred:', err);
          console.log('Error Message:', this.error);
          console.log(err.error.error);
        });
  }

}
